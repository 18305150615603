import styled from 'styled-components';

const Style = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 5% 5%;
    flex-direction: column;

    span {
        display: block;
        margin-bottom: 0.25rem;
        font-weight: 600;
        font-size: 1rem;
        width: 100%;
        white-space: pre-line;
    }

    .video {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        position: relative;

        video {
            position: relative;
            z-index: 10;
            outline: none;
            max-height: 100%;
            max-width: 100%;
            box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
            background-color: #000;
            cursor: pointer;
        }

        .videoPlaceholder {
            position: fixed;
            z-index: 20;
            outline: none;
            max-height: 40%;
            width: 620px;
            background-color: #000000dd;
            cursor: pointer;
            padding: 20px;
            font-size: 1.5em;
            color: white;
        }

        .subtitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 20;
            left: 0;
            right: 0;
            bottom: 5%;
            width: 100%;
            padding: 0 20px;
            user-select: none;
            pointer-events: none;

            .operate {
                padding: 5px 15px;
                color: #fff;
                font-size: 13px;
                border-radius: 3px;
                margin-bottom: 5px;
                background-color: rgb(0 0 0 / 75%);
                border: 1px solid rgb(255 255 255 / 20%);
                cursor: pointer;
                pointer-events: all;
            }

            .textarea {
                user-select: none;
                width: 100%;
                outline: none;
                resize: none;
                text-align: center;
                line-height: 1.2;
                border: none;
                color: #fff;
                font-size: 20px;
                padding: 5px 10px;
                background-color: rgb(0 0 0 / 0);
                text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
                    rgb(0 0 0) 0px -1px 1px;
            }

            .container ul {
                padding: 0;
                z-index: 100;
                background-color: rgb(32, 32, 32);

                li:first-of-type {
                    font-weight: bold;
                    color: white;
                    background-color: rgb(0 87 158);
                }
            }
        }
    }
`;

export default Style;
