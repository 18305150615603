import React, { useState, useEffect, createRef, useCallback, useMemo, memo } from 'react';
import backlight from '../../libs/backlight';
import { isPlaying } from '../../utils';
import Style from './style';

const VideoWrap = memo(
    ({ setPlayer, setCurrentTime, setPlaying, project }) => {
        const $video = createRef();

        useEffect(() => {
            setPlayer($video.current);
            (function loop() {
                window.requestAnimationFrame(() => {
                    if ($video.current) {
                        setPlaying(isPlaying($video.current));
                        setCurrentTime($video.current.currentTime || 0);
                    }
                    loop();
                });
            })();
        }, [setPlayer, setCurrentTime, setPlaying, $video]);

        const onClick = useCallback(() => {
            if ($video.current) {
                if (isPlaying($video.current)) {
                    $video.current.pause();
                } else {
                    $video.current.play();
                }
            }
        }, [$video]);

        return (
            <video
                disablePictureInPicture
                controlsList="nodownload"
                onClick={onClick}
                src={project.job.projectInfos.videoUrl}
                ref={$video}
            />
        );
    },
    () => true,
);

export default function Player(props) {
    const [currentSub, setCurrentSub] = useState(null);
    const $player = createRef();

    useEffect(() => {
        if ($player.current && props.player && !backlight.state) {
            backlight.state = true;
            backlight($player.current, props.player);
        }
    }, [$player, props.player]);

    useMemo(() => {
        setCurrentSub(props.subtitle[props.currentIndex]);
    }, [props.subtitle, props.currentIndex]);

    return (
        <Style className="player">
            <span>{props.name}</span>
            <div className="video" ref={$player}>
                <VideoWrap {...props} />
                {props.player && currentSub ? (
                    <div className="subtitle">
                        {!currentSub.text2 ? (
                            <span className="textarea">{currentSub.text}</span>
                        ) : (
                            <span className="textarea">{currentSub.text2}</span>
                        )}
                    </div>
                ) : null}
            </div>
        </Style>
    );
}
