/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import DT from 'duration-time-conversion';
import isEqual from 'lodash/isEqual';
import Tool from './components/tool/Tool';
import Subtitles from './components/subtitles/Subtitles';
import Player from './components/player/Player';
import Footer from './components/footer/Footer';
import Loading from './components/loading/Loading';
import ProgressBar from './components/progress-bar/ProgressBar';
import { getKeyCode, getParmFromUrl } from './utils';
import Sub from './libs/Sub';
import Style from './style';
import { getProjectDetails, getSubtitles } from './services/project.http';
import srt2vtt from './libs/readSub/srt2vtt';
import { url2sub, vtt2url } from './libs/readSub';
import ErrorMessage from './components/errorMessage';

export default function App({ defaultLang }) {
    const subtitleHistory = useRef([]);
    const notificationSystem = useRef(null);
    const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState('');
    const [processing, setProcessing] = useState(0);
    const [language, setLanguage] = useState(defaultLang);
    const [subtitle, setSubtitleOriginal] = useState([]);
    const [waveform, setWaveform] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [context, setContext] = useState({});
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getProjectDetails();
            if (result.isError) {
                setErrorMessage(result.errorMessage);
            } else {
                setContext(result.data);
                let subtitlesResult;
                if (result.data.job.contributorSubtitlesUrl) {
                    subtitlesResult = await getSubtitles(result.data.job.contributorSubtitlesUrl);
                } else {
                    subtitlesResult = await getSubtitles(result.data.job.subtitlesUrl);
                }
                if (subtitlesResult.isError) {
                    setErrorMessage(subtitlesResult.errorMessage);
                } else {
                    const vtt = srt2vtt(subtitlesResult.data);
                    const urlSubtitles = vtt2url(vtt);
                    const sub = await url2sub(urlSubtitles);
                    clearSubs();
                    setSubtitle(sub);
                }
            }
        };
        const token = getParmFromUrl('token');
        const job = getParmFromUrl('job');
        if (!token || !job) {
            setErrorMessage('Missing parameters in the URL');
        } else {
            fetchData();
        }
    }, []);

    const newSub = useCallback((item) => new Sub(item), []);
    const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle]);

    const formatSub = useCallback(
        (sub) => {
            if (Array.isArray(sub)) {
                return sub.map((item) => newSub(item));
            }
            return newSub(sub);
        },
        [newSub],
    );

    const copySubs = useCallback(() => formatSub(subtitle), [subtitle, formatSub]);

    const setSubtitle = useCallback(
        (newSubtitle, saveToHistory = true) => {
            if (!isEqual(newSubtitle, subtitle)) {
                if (saveToHistory) {
                    if (subtitleHistory.current.length >= 1000) {
                        subtitleHistory.current.shift();
                    }
                    subtitleHistory.current.push(formatSub(subtitle));
                }
                window.localStorage.setItem('subtitle', JSON.stringify(newSubtitle));
                setSubtitleOriginal(newSubtitle);
            }
        },
        [subtitle, setSubtitleOriginal, formatSub],
    );

    const clearSubs = useCallback(() => {
        setSubtitle([]);
        subtitleHistory.current.length = 0;
    }, [setSubtitle, subtitleHistory]);

    const checkSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const previous = subtitle[index - 1];
            return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.2;
        },
        [subtitle, hasSub],
    );

    const notify = useCallback(
        (obj) => {
            // https://github.com/igorprado/react-notification-system
            const notification = notificationSystem.current;
            notification.clearNotifications();
            notification.addNotification({
                position: 'tc',
                dismissible: 'none',
                autoDismiss: 2,
                message: obj.message,
                level: obj.level,
            });
        },
        [notificationSystem],
    );

    const removeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            subs.splice(index, 1);
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle],
    );

    const addSub = useCallback(
        (index, sub) => {
            const subs = copySubs();
            subs.splice(index, 0, formatSub(sub));
            setSubtitle(subs);
        },
        [copySubs, setSubtitle, formatSub],
    );

    const updateSub = useCallback(
        (sub, obj) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const subClone = formatSub(sub);
            Object.assign(subClone, obj);
            if (subClone.check) {
                subs[index] = subClone;
                setSubtitle(subs);
            }
        },
        [hasSub, copySubs, setSubtitle, formatSub],
    );

    const mergeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const next = subs[index + 1];
            if (!next) return;
            let merge;
            if (!sub.text2) {
                merge = newSub({
                    start: sub.start,
                    end: next.end,
                    text: sub.text.trim() + '\n' + next.text.trim(),
                });
            } else {
                merge = newSub({
                    start: sub.start,
                    end: next.end,
                    text: sub.text.trim() + '\n' + next.text.trim(),
                    text2: sub.text2.trim() + '\n' + next.text2.trim(),
                });
            }
            subs[index] = merge;
            subs.splice(index + 1, 1);
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle, newSub],
    );

    const splitSub = useCallback(
        (sub, start) => {
            const index = hasSub(sub);
            if (index < 0 || !sub.text || !start) return;
            const text = !sub.text2 ? sub.text : sub.text2;
            const subs = copySubs();
            const textLeft = text.slice(0, start).trim();
            const textRight = text.slice(start).trim();
            if (!textLeft || !textRight) return;
            const splitDuration = (sub.duration * (start / text.length)).toFixed(3);
            if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
            subs.splice(index, 1);
            const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
            if (!sub.text2) {
                subs.splice(
                    index,
                    0,
                    newSub({
                        start: sub.start,
                        end: middleTime,
                        text: textLeft,
                        text2: sub.text2 ? sub.text2 : '',
                    }),
                );
                subs.splice(
                    index + 1,
                    0,
                    newSub({
                        start: middleTime,
                        end: sub.end,
                        text: textRight,
                        text2: sub.text2 ? sub.text2 : '',
                    }),
                );
            } else {
                subs.splice(
                    index,
                    0,
                    newSub({
                        start: sub.start,
                        end: middleTime,
                        text: sub.text,
                        text2: textLeft,
                    }),
                );
                subs.splice(
                    index + 1,
                    0,
                    newSub({
                        start: middleTime,
                        end: sub.end,
                        text: sub.text,
                        text2: textRight,
                    }),
                );
            }
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle, newSub],
    );

    const onKeyDown = useCallback(
        (event) => {
            const keyCode = getKeyCode(event);
            switch (keyCode) {
                case 9:
                    event.preventDefault();
                    if (player) {
                        if (playing) {
                            player.pause();
                        } else {
                            player.play();
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        [player, playing],
    );

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    useMemo(() => {
        const currentIndex = subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime);
        setCurrentIndex(currentIndex);
    }, [currentTime, subtitle]);

    const props = {
        player,
        setPlayer,
        subtitle,
        setSubtitle,
        waveform,
        setWaveform,
        currentTime,
        setCurrentTime,
        currentIndex,
        setCurrentIndex,
        playing,
        setPlaying,
        language,
        setLanguage,
        loading,
        setLoading,
        setProcessing,
        subtitleHistory,
        notify,
        newSub,
        hasSub,
        checkSub,
        removeSub,
        addSub,
        clearSubs,
        updateSub,
        formatSub,
        mergeSub,
        splitSub,
        project: context,
    };

    if (errorMessage) return <ErrorMessage text={errorMessage} />;

    if (!context?.job?.projectInfos?.videoUrl) return null;

    return (
        <Style>
            <div className="main">
                <Player {...props} />
                <Subtitles {...props} />
                <Tool {...props} />
            </div>
            <Footer {...props} />
            {loading ? <Loading loading={loading} /> : null}
            {processing > 0 && processing < 100 ? <ProgressBar processing={processing} /> : null}
            <NotificationSystem ref={notificationSystem} allowHTML={true} />
        </Style>
    );
}
