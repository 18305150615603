import React from 'react';
import Style from './style';

export default function Component({ processing }) {
    return (
        <Style>
            <div className="inner" style={{ width: `${processing}%` }}>
                <span>{`${processing.toFixed(2)}%`}</span>
            </div>
        </Style>
    );
}
