import styled from 'styled-components';

const Style = styled.div`
    height: 100%;
    width: 100%;

    .main {
        display: flex;
        height: calc(100% - 200px);

        .player {
            flex: 1;
        }

        .subtitles {
            width: 400px;
        }

        .tool {
            width: 200px;
        }
    }

    .footer {
        height: 200px;
    }
`;

export default Style;
