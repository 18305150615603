const en = {
    LOADING: 'Loading...',
    SUB_TEXT: 'New item',
    OPEN_SUB: 'Open Subtitle',
    EXPORT_ASS: 'Export ASS',
    EXPORT_SRT: 'Export SRT',
    UPLOAD_SRT: 'Save',
    EXPORT_VTT: 'Export VTT',
    EXPORT_VIDEO: 'Export Video',
    DECODE_START: 'Start decoding audio',
    DECODE_SUCCESS: 'Decoding audio success',
    DECODE_ERROR: 'Decoding audio failed',
    VIDEO_EXT_ERR: 'Unable to open this video format',
    MOBILE_TIP: 'Please use desktop access',
    HOTKEY_01: 'Tab: Play / Pause',
    HOTKEY_02: 'Ctrl + Z: Undo',
    CLEAR: 'Clear',
    UNDO: 'Undo',
    DELETE: 'Delete subtitle',
    MERGE: 'Merge next',
    SPLIT: 'Split subtitle',
    LOADING_FFMPEG: 'Loading dependency...',
    LOADING_FONT: 'Loading font...',
    LOADING_VIDEO: 'Loading video...',
    LOADING_SUB: 'Loading subtitle...',
    CLEAR_TIP: 'Confirm that all data is cleared',
    BURN_ERROR: 'Burn subtitles failed',
    BURN_START: 'Start burn subtitles',
    BURN_SUCCESS: 'Burn subtitles success',
};

const i18n = {
    en,
};

export default i18n;
