import { getParmFromUrl } from '../utils';

export const getProjectDetails = async () => {
    try {
        const result = await fetch(
            `${import.meta.env.VITE_APP_ENDPOINT}subplayer/jobs/${getParmFromUrl('job')}/context`,
            {
                headers: { Authorization: `Bearer ${getParmFromUrl('token')}` },
            },
        );
        if ([500, 400, 404, 404].includes(result.status)) {
            throw new Error('Unable to load data');
        }
        return {
            data: await result.json(),
            isError: false,
            errorMessage: null,
        };
    } catch (error) {
        return {
            data: null,
            isError: true,
            errorMessage: error.message,
        };
    }
};

export const getSubtitles = async (url) => {
    try {
        const result = await fetch(url);
        if ([500, 400, 404, 404].includes(result.status)) {
            throw new Error('Unable to load data');
        }
        return {
            data: await result.text(),
            isError: false,
            errorMessage: null,
        };
    } catch (error) {
        return {
            data: null,
            isError: true,
            errorMessage: error.message,
        };
    }
};

export const uploadSubtitles = async (subtitles) => {
    try {
        const formData = new FormData();
        const blob = new Blob([subtitles], { type: 'text/plain' });
        formData.append('srt_file', blob, 'file.txt');
        const result = await fetch(
            `${import.meta.env.VITE_APP_ENDPOINT}subplayer/jobs/${getParmFromUrl('job')}/callback`,
            {
                headers: { Authorization: `Bearer ${getParmFromUrl('token')}` },
                method: 'POST',
                body: formData,
            },
        );
        if ([500, 400, 404, 404].includes(result.status)) {
            throw new Error('Unable to upload data');
        }
        return {
            isError: false,
            errorMessage: null,
        };
    } catch (error) {
        return {
            isError: true,
            errorMessage: error.message,
        };
    }
};
