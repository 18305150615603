import styled from 'styled-components';

const Style = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    & div {
        background-color: #222222;
        padding: 2rem;
        border-radius: 5px;
        text-align: center;
        & b {
            color: #f05856;
            display: block;
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
        }
    }
`;

export default function ErrorMessage({ text }) {
    return (
        <Style>
            <div>
                <b>An error occurred</b>
                <span>{text}</span>
            </div>
        </Style>
    );
}
