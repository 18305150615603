import { Translate } from 'react-i18nify';
import React, { useCallback, useState } from 'react';
import { sub2srt } from '../../libs/readSub';
import Style from './style';
import { uploadSubtitles } from '../../services/project.http';
import { download } from '../../utils/index.js';

export default function Header({ subtitle, setLoading }) {
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

    const uploadSrt = async () => {
        const text = sub2srt(subtitle, false);
        setLoading(true)
        const response = await uploadSubtitles(text);
        setLoading(false)
        if(response.isError) {
            alert(response.errorMessage)
        } else {
            setIsSuccessModalVisible(true);
        }
    };

    const downloadSub = useCallback(
        (type) => {
            let text = '';
            const name = `${Date.now()}.${type}`;
            switch (type) {
                case 'srt':
                    text = sub2srt(subtitle, false);
                    break;
                default:
                    break;
            }
            const url = URL.createObjectURL(new Blob([text]));
            download(url, name);
        },
        [subtitle],
    );


    return (
        <Style className="tool">
            <div className="top">
                <div className="export">
                    <div className="btn" onClick={uploadSrt}>
                        <Translate value="UPLOAD_SRT" />
                    </div>
                    <div className="btn" onClick={() => downloadSub('srt')}>
                        <Translate value="EXPORT_SRT" />
                    </div>
                </div>
                <div className="hotkey">
                    <span>
                        <Translate value="HOTKEY_01" />
                    </span>
                </div>
            </div>
            {isSuccessModalVisible && (
                <div className="success-modal">
                    <div className="modal-content">
                        <span className="modal-title">Success</span>
                        <span>The subtitles have been saved, you can close this page.</span>
                        <span className="close-page" onClick={() => window.close()}>
                            Close page
                        </span>
                    </div>
                </div>
            )}
        </Style>
    );
}
